import css from './index.module.scss'

function RateImg(props) {
  let {rate = 'auto', img, onClick, className} = props;
  return (
      <div className={`${css['rate-img']} ${className}`} onClick={onClick}>
        <div className={css['rate-img-box']} style={{paddingTop: rate}}>
          <img width="auto" height="auto" src={img} alt=""/>
        </div>
      </div>
  );
}

export default RateImg;