import dayjs from 'dayjs';
import $ from 'jquery';

/**
 * 判断变量的类型
 * @param val
 * @param type
 * @returns {Boolean}
 */
export function checkType (val, type) {
    if (Array.isArray(type)) {
        return type.some(t => Object.prototype.toString.call(val) === `[object ${t}]`);
    } else {
        return Object.prototype.toString.call(val) === `[object ${type}]`;
    }
}

/**
 * 变量转boolean
 * @param value
 * @returns {boolean}
 */
export function coerceBoolean (value) {
    return value !== null && value !== undefined && `${value}` !== 'false' && `${value}` !== 'NaN' && `${value}` !== '';
}

/**
 * 判断变量的类型
 * @param val
 * @returns {string}
 */
export function getObjectType (val) {
    return Object.prototype.toString.call(val);
}

/**
 * 将Blob转换为字符串（异步）
 * @param blob Blob对象
 * @returns {Promise<any>}
 */
export function blobToString (blob) {
    return new Promise(((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsText(blob);
        reader.onload = function () {
            resolve(this.result);
        };
        reader.onerror = function () {
            reject(this.error);
        };
    }));
}

export const parseTime = (time, format = 'YYYY-MM-DD') => {
    return time ? dayjs(time).format(format) : '';
};

// 渐变函数
export function transition (start = [], end = [], fn, time = 500, delay = 20) {
    if (!checkType(start, 'Array')) start = [start];
    if (!checkType(end, 'Array')) end = [end];
    let maxIndex = time / delay;
    let unit = start.map((val, idx) => (end[idx] - val) / maxIndex);
    let index = 0;
    let timer = setInterval(() => {
        index++;
        let isLast = maxIndex === index;
        try {
            fn(...(isLast ? end : start.map((val, idx) => val + unit[idx] * index)));
        } catch (e) {
            console.error(e);
        }
        if (isLast) {
            clearInterval(timer);
        }
    }, delay);
    return timer;
}

// 成一个渐变函数，改渐变函数在连续调用时会中断上一个渐变逻辑
export function getTransition () {
    let timer;
    return (...argv) => {
        clearInterval(timer);
        timer = transition(...argv);
    };
}

// 把各种class设置方式统一转化为String格式
export function parseClass (className, ...argv) {
    if (checkType(className, 'String')) {
        return className;
    } else if (checkType(className, 'Function')) {
        return parseClass(className(...argv));
    } else if (checkType(className, 'Array')) {
        return className.map(v => parseClass(v, ...argv)).join(' ');
    } else if (checkType(className, 'Object')) {
        return parseClass(Object.keys(className).filter(key => className[key]), ...argv);
    }
}

// 合并className
export function concatClass (...argv) {
    return argv.filter(s => s && checkType(s, 'String')).join(' ');
}

// 动态修改tdk
export function setTdk (title, desc, keyword) {
    document.getElementsByName('keywords')[0].content = keyword;
    document.getElementsByName('description')[0].content = desc;
    document.getElementsByTagName("title")[0].innerHTML = title;
}

export function isMobile () {
    let systemCode;
    let ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox &&
            /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
    // 进行判断
    if (isAndroid || isPhone) {
        systemCode = 'app';
    } else if (isTablet) {
        systemCode = 'iPad'
    } else if (isPc) {
        // 苹果浏览器下 iPad 需要特殊处理下
        // iPad特殊分辨率1024*1366判断
        let swidth = window.screen.width;
        let sheight = window.screen.width;
        if (window.screen.height > swidth) {
            sheight = window.screen.height;
        } else {
            swidth = window.screen.height;
        }

        if (swidth === '1024' && sheight === '1366') {
            systemCode = 'iPad';
        }
        else if (Number(window.screen.width) < Number(window.screen.height)) {
            systemCode = 'iPad';
        } else {
            systemCode = 'PC';
        }
    }
    console.log(systemCode);
    return systemCode !== 'PC'
    // return !!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
}

// 触发原生dom事件
export function trigger(el, type) {
    // 创建事件.
    const e = document.createEvent('HTMLEvents')
    // 指示事件是自定义的
    e.isCustom = true;
    // 初始化一个点击事件，可以冒泡，无法被取消
    e.initEvent(type, true, true)
    // 触发事件监听
    el.dispatchEvent(e)
}

// 触发原生dom事件
export function scrollTo(top) {
    document.body.scrollTop = document.documentElement.scrollTop = top;
    trigger(document.body, 'scroll');
}

// 锁定滚动
export const lockScroll = function() {
    let map = {};
    let id = 0;
    return function lock () {
        let _id = id++;
        map[_id] = 1;
        $(document.body).addClass('overflow-hidden');
        return function unlock () {
            delete map[_id];
            if (!Object.keys(map).length) {
                $(document.body).removeClass('overflow-hidden');
            }
        }
    }
}();

// 设置图片路径，oss直传的和老方法上传的区分
export function setOssUrl(url) {
    if(!url){
        return ""
    } else if (url.includes("http://") || url.includes("https://")) {
        return url;
    } else if (url.includes("/fileserver/")) {
        return "//download.djcps.com/" + url;
    } else {
        return url;
    }
}
