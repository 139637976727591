
/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-15 20:54:40
 * @LastEditors: dingxuejin
 */
import { lazy, PureComponent as Component } from "react";

const BaseLayout = lazy(() => import("@/components/layout/baseLayout"));

class NorLayout extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="dj-specLayout">
                <BaseLayout layout="4" {...this.props}>{this.props.children}</BaseLayout>
            </div >
        );
    }
}

export default NorLayout;

