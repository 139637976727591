
/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-07 21:03:01
 * @LastEditors: dingxuejin
 */
import { lazy, PureComponent as Component } from "react";
import './index.scss';

const BaseLayout = lazy(() => import("@/components/layout/baseLayout"));

class IndexLayout extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="dj-indexLayout">
                <BaseLayout layout="1" {...this.props}>{this.props.children}</BaseLayout>
            </div >
        );
    }
}

export default IndexLayout;

