/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-17 14:06:11
 * @LastEditTime: 2021-02-17 14:25:49
 * @LastEditors: dingxuejin
 */
import { lazy } from "react";
const NorLayout = lazy(() => import("@/components/layout/norLayout"));
const NomalLayout = lazy(() => import("@/components/layout/nomalLayout"));
const News = lazy(() => import("@/pages/news"));

const videosCenter = lazy(() => import("@/pages/news/subPages/videosCenter"));
const enterpriseNews = lazy(() => import("@/pages/news/subPages/enterpriseNews"));
const industryInformation = lazy(() => import("@/pages/news/subPages/industryInformation"));
const details = lazy(() => import("@/pages/news/subPages/details"));

export default {
    path: "/news",
    description: "新闻中心",
    bgImage: require('@/assets/images/news/banner/banner_news.jpg').default,
    component: News,
    layout: NomalLayout,
    title: "新闻中心-浙江东经科技股份有限公司-包装产业互联网研究院",
    desc: "东经科技为纸包装行业提供一站式解决方案，包含进销存、纸包装交易平台、ERP、CRM、智能仓配系统等等一体化的信息化管理服务，实现“降本、提效、增值”。",
    keyword: "ERP系统，CRM智能营销系统，智能仓配系统，进销存管理系统，东经易网，易单来，易纸箱，易智配，专注纸包装行业",
    children: [
        {
            path: "/enterpriseNews",
            description: "企业动态",
            bgImage: require('@/assets/images/news/banner/banner_ours.jpg').default,
            component: enterpriseNews,
            layout: NomalLayout,
            title: "企业动态-浙江东经科技股份有限公司-包装产业互联网研究院",
            desc: "为纸包装行业提供进销存、纸包装交易平台、ERP、CRM、智能仓配系统等等一体化的信息化管理服务，实现“降本、提效、增值”。",
            keyword: "ERP系统，CRM智能营销系统，智能仓配系统，进销存管理系统，东经易网，易单来，易纸箱，易智配，专注纸包装行业"
        },
        {
            path: "/industryInformation",
            description: "行业资讯",
            bgImage: require('@/assets/images/news/banner/banner_market.jpg').default,
            component: industryInformation,
            layout: NomalLayout,
            title: "行业资讯-浙江东经科技股份有限公司-包装产业互联网研究院",
            desc: "为纸包装行业提供进销存、纸包装交易平台、ERP、CRM、智能仓配系统等等一体化的信息化管理服务，实现“降本、提效、增值”。",
            keyword: "ERP系统，CRM智能营销系统，智能仓配系统，进销存管理系统，易单来，易纸箱，易智配专注纸包装行业"
        },
        {
            path: "/videosCenter",
            description: "视频中心",
            bgImage: require('@/assets/images/news/banner/banner_videos.jpg').default,
            component: videosCenter,
            layout: NomalLayout,
            title: "视频中心-浙江东经科技股份有限公司-包装产业互联网研究院",
            desc: "为纸包装行业提供进销存、纸包装交易平台、ERP、CRM、智能仓配系统等等一体化的信息化管理服务，实现“降本、提效、增值”。",
            keyword: "ERP系统，CRM智能营销系统，智能仓配系统，进销存管理系统，东经易网，易单来，易纸箱，易智配，专注纸包装行业"
        },
        {
            path: "/details/:type/:id",
            description: "详情页",
            component: details,
            layout: NorLayout,
            nav: 0,
            title: "",
            desc: "",
            keyword: ""
        }
    ]
}
