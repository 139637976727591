/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-14 17:51:44
 * @LastEditTime: 2021-02-21 17:13:59
 * @LastEditors: dingxuejin
 */

import { PureComponent as Component } from "react";
import css from './index.module.scss';

class Item extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {id, subTitle} = this.props;
        return (
            <div className={`${css["item"]} ${this.props.className}`} style={this.props.bgcolor ? { backgroundColor: this.props.bgcolor } : {}}>
                {this.props.title ? <div id={id} className={css["titl"]} style={this.props.color ? { color: this.props.color } : {}}>{this.props.title}</div> : ""}
                { subTitle && <p className={css['sub-title']}>{subTitle}</p>}
                { this.props.children}
            </div >
        );
    }
}

export default Item;
