import {PureComponent as Component} from "react";
import { withRouter } from 'react-router-dom'
import case_1 from '@/assets/images/other/404.png';
import css from './index.module.scss';

class notFound extends Component {
  render() {
    return (
      <div className={css["notfound"]}>
        <img className={css["not_img"]} src={case_1} alt=""/>
        <div className={css["not_con"]}>
          <p className={css["con_text"]}>你要找的页面不见了…</p>
          <a href="/" className={css["con_home"]}>返回首页</a>
        </div>
      </div>
    );
  }
}

export default withRouter(notFound);
