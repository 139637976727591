/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-17 14:06:48
 * @LastEditTime: 2021-02-19 13:50:07
 * @LastEditors: dingxuejin
 */
import { lazy } from "react";
const NomalLayout = lazy(() => import("@/components/layout/nomalLayout"));
const SpecLayout = lazy(() => import("@/components/layout/specLayout"));
const Contact = lazy(() => import("@/pages/contact"));

const Channel = lazy(() => import("@/pages/contact/channel"));
const Join = lazy(() => import("@/pages/contact/join"));

export default {
    path: "/contact",
    description: "联系我们",
    redirect: "/channel",
    children: [
        {
            path: "/channel",
            description: "渠道招募",
            details: "东经科技邀您一起掘金纸包装行业“第四次工业革命”携手迈入包装+互联网时代",
            bgImage: require('@/assets/images/contact/banner/contact_banner_bg.jpg').default,
            component: Channel,
            layout: NomalLayout,
            title: "渠道招募-浙江东经科技股份有限公司-包装产业互联网研究院",
            desc: "浙江东经科技股份有限公司，地址：浙江省温州市瓯海经济开发区东经一路1号，客户服务热线：400-133-6161，联系电话：0577-85399999",
            keyword: "东经科技，东经易网，东经优势，东经合作支持"
        },
        {
            path: "https://www.zhipin.com/gongsi/job/8ed6ac955a2d16911Xx82dW5.html",
            description: "加入我们",
            outLink: true
        },
        {
            path: "/join",
            description: "联系我们",
            component: Join,
            layout: SpecLayout,
            nav: 0,
            title: "联系我们-浙江东经科技股份有限公司-包装产业互联网研究院",
            desc: "浙江东经科技股份有限公司，地址：浙江省温州市瓯海经济开发区东经一路1号，客户服务热线：400-133-6161，联系电话：0577-85399999",
            keyword: "ERP系统，CRM智能营销系统，智能仓配系统，进销存管理系统，东经易网，易单来，易纸箱，易智配，专注纸包装行业"
        }
    ]
}
