import React, {PureComponent as Component} from 'react';
import css from './index.module.scss'
import {concatClass, parseClass} from "../../../utils/methods";
export default class Tab extends Component {
  constructor(props) {
    super(props);
  }

  onClick = (item) => {
    if (item.value === this.props.value) return;
    this.props.onChange && this.props.onChange(item);
  }

  render() {
    let {options = [], value} = this.props;
    return (
      <div className={css['tab']}>
        {
          options.map(item => (
            <div className={concatClass(css['tab-item'], value === item.value ? css['is-active'] : '')} onClick={this.onClick.bind(this, item)}>
              <span>{item.label}</span>
            </div>
          ))
        }
      </div>
    )
  }
}
