/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-17 14:04:14
 * @LastEditTime: 2021-02-19 14:17:36
 * @LastEditors: dingxuejin
 */
import { lazy } from "react";
const NomalLayout = lazy(() => import("@/components/layout/nomalLayout"));
const About = lazy(() => import("@/pages/about"));

export default {
    path: "/about",
    description: "关于我们",
    details: "",
    bgImage: require('@/assets/images/about/about_banner_bg.jpg').default,
    component: About,
    layout: NomalLayout,
    title: "关于我们-浙江东经科技股份有限公司-包装产业互联网研究院",
    desc: "浙江东经科技股份有限公司创办于1994年，近30年的纸包装行业运营及管理经验；开创并实际应用“包装+互联网”模式。围绕生产经营全流程各环节痛点，为纸包装用户提供进销存、纸包装交易平台、ERP、CRM、智能仓配系统等一体化的信息化管理服务，赋能纸包装行业。",
    keyword: "东经科技,东经易网,一路好运,易单来，易智配，易纸箱，纸板团购,包装铺子,包装供应链服务平台，纸板交易平台，纸箱厂erp管理系统，纸板厂crm智能营销系统，智能仓配系统",
    children: [
        {
            path: "?int",
            description: "关于东经",
            name: "int"
        },
        {
            path: "?cul",
            description: "东经文化",
            name: "cul"
        },
        {
            path: "?his",
            description: "发展历程",
            name: "his"
        },
        {
            path: "?hon",
            description: "东经荣誉",
            name: "hon"
        },
        {
            path: "?area",
            description: "覆盖区域",
            name: "area"
        },
        {
            path: "?dyn",
            description: "东经动态",
            name: "dyn"
        }
    ]
}
