import {PureComponent} from 'react';
import dayjs from 'dayjs';
import DetailBtn2 from '../../components/DetailBtn2';
import Ellipse from '../../components/Ellipse';
import css from './index.module.scss'

class LineItem extends PureComponent {
  constructor() {
    super();
  }

  render() {
    let {item = {}, onClick} = this.props;
    let {img, time = '', title = '', subTitle = ''} = item;
    let date = dayjs(time).format('MM-DD');
    let year = dayjs(time).year();
    return (
        <div className={css['item']} onClick={onClick}>
          <div className={css['item-time']}>
            <p className={css['item-time-date']}>{date}</p>
            <p className={css['item-time-year']}>{year}</p>
          </div>
          <div className={css['item-img']} style={{backgroundImage: `url(${img})`}}></div>
          <div className={css['item-content']}>
            <p className={`${css['item-content-title']} ellipse`} title={title}>{title}</p>
            <p className={css['item-content-sub-title']}><Ellipse max={105}>{subTitle}</Ellipse></p>
          </div>
          <div className={css['item-btn']}>
            <DetailBtn2 className={css['btn']}></DetailBtn2>
          </div>
        </div>
    );
  }
}

export default LineItem;