/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-15 16:33:47
 * @LastEditTime: 2021-02-15 16:34:49
 * @LastEditors: dingxuejin
 */
import { PureComponent as Component } from "react";
import './index.scss'

class Dec extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="dj-home-dec">
                <p>东经科技以“包装+互联网” 模式</p>
                <p>打造中小企业降本提效，价值倍增包装供应链服务平台</p>
                <p>实现全产业链各利益相关方共赢</p>
            </div>
        );
    }
}

export default Dec;
