/* Automatically generated by './auto/release/build-entry.js' */

import HttpFactory from './httpFactory';
import HttpPolicy from './httpPolicy';
const Version = '0.0.16';
export {
  Version,
  HttpFactory,
  HttpPolicy
}

