/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-15 20:58:06
 * @LastEditors: dingxuejin
 */

import {lazy, PureComponent as Component} from "react";
import {withRouter} from 'react-router-dom'
import './index.scss';
import {scrollTo, setTdk} from "../../../utils/methods";

const Header = lazy(() => import("@/components/common/header"));
const Display = lazy(() => import("@/components/common/display"));
const Footer = lazy(() => import("@/components/common/footer"));

{/* layout:1:主页骨架,2,常规骨架,3,内页骨架 */}

@withRouter
class baseLayout extends Component {
  constructor(props) {
    super(props);
    this.setTdkFun()
    if (this.props.route.scrollTop !== 0) {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    scrollTo(0);
  }

  setTdkFun(){
      if(this.props.route.title || this.props.route.tdks){
        let title=this.props.route.title || this.props.route.tdks[this.props.match.params.id].title || "";
        let desc=this.props.route.desc ;
        if(desc===undefined){
            desc=this.props.route.tdks[this.props.match.params.id].desc
        }
        let keyword=this.props.route.keyword;
        if(keyword===undefined){
            keyword=this.props.route.tdks[this.props.match.params.id].keyword
        }
        setTdk(title,desc,keyword)
      }
  }

  render() {
    return (
      <div className="dj-baseLayout">
        <header>
          <Header {...this.props}></Header>
        </header>
        <section>
          <Display {...this.props}></Display>
        </section>
        <section>
          {this.props.children}
        </section>
        {
          this.props.layout != 3 ? (<footer>
            <Footer></Footer>
          </footer>) : ""
        }

      </div>
    );
  }
}

export default baseLayout;

