/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-21 14:33:51
 * @LastEditTime: 2021-02-21 16:51:45
 * @LastEditors: dingxuejin
 */

import { lazy, PureComponent as Component } from "react";
import css from './index.module.scss';
import Slider from "../../../components/common/Slider";
import infoService from '@/api/service/info'
import {setOssUrl} from "@/utils/methods/_common"

const Item = lazy(() => import("@/pages/product/components/item/index"));
// const Swiper = lazy(() => import("@/components/common/swiper"));

class Honor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
    }
    componentDidMount() {
        this.getList();
    }

    handleList(data) {
        // var result = [];
        // for (let i = 0; i < data.length; i += 3) {
        //     result.push(data.slice(i, i + 3));
        // }
        this.setState({
            list: data.map((item)=>{
                return {
                    ...item,
                    fmainpic: setOssUrl(item.fmainpic)
                }
            })
        })
    }

    getList = () => {
        infoService.honorList().then(res => {
            this.total = res.total;
            this.handleList(res.list)
        }).finally(() => {

        });

    }
    render() {
        return (
            <Item title="东经荣誉" className={css['honer']}>
                <div className={css["con"]} id="hon">
                    <div className={css["warp"]}>
                        <Slider>
                            {/*<Swiper>*/}
                            {
                                this.state.list.map((item, index) => {
                                    // return (< div className="swiper-slide" key={index}>
                                    //     {
                                    //         item.map((item1, index) => {
                                    return (<div className={css["itm"]} key={index}>
                                        <div className={css["img"]}>
                                            <img src={item.fmainpic} alt=""/>
                                        </div>
                                        <div className={css["con"]}>
                                            <div className={`${css["dec"]} ellipse`}>{item.fabstract}</div>
                                            <div className={`${css["tet"]} ellipse`} >{item.ftitle}</div>
                                        </div>
                                    </div>)
                                    // })
                                    // }
                                    // </div>

                                    // )
                                })
                            }
                            {/*</Swiper>*/}
                        </Slider>
                    </div>
                </div >
            </Item >
        );
    }
}

export default Honor;
