import { fromJS } from 'immutable'; //用这个包创建的对象不可被更改
const DEFAULT_STATE = fromJS({
  //
  isMobile: false
});

const ACTION_NAME = {
  changeIsMobile: 'changeIsMobile',
}

const actionMap = {
  [ACTION_NAME.changeIsMobile]: (state, action)=>{
    return state.set('isMobile', action.preload);
  }
};

export default (state = DEFAULT_STATE, action) => {
  let fn = actionMap[action.type];
  if (fn) {
    return fn(state, action);
  }
  return state
}

export const changeIsMobile = (val) => {
  return {
    type: ACTION_NAME.changeIsMobile,
    preload: val
  }
}
