/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-19 20:43:37
 * @LastEditTime: 2021-02-21 16:54:43
 * @LastEditors: dingxuejin
 */
import { PureComponent as Component } from "react";
import css from './index.module.scss';

class Concat extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        let {children = '联系我们，解决您纸板厂的经营难题'} = this.props;
        return (
            <div className={css["con"]}>
                <div className={css["warp"]}>
                    <div className={css["titl"]}>{children}</div>
                    <div className={css["btn-warp"]}><div className={css["btn"]} onClick={() => this.props.history.push(`/contact/join`)}>联系我们</div></div>
                </div>
            </div>
        )
    }
}

export default Concat;