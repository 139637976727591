/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-07 20:31:06
 * @LastEditors: dingxuejin
 */
import {PureComponent as Component} from "react";
import { withRouter } from 'react-router-dom'
import Block from './components/Block'
import ItemGroup from './components/ItemGroup'
import SwiperItem from './components/SwiperItem'
import Item from './components/Item'
import css from './index.module.scss'
import infoService from '../../api/service/info'
import {parseTime} from '../../utils/methods';
import enums from '../../utils/enum';
import { Spin } from 'antd';
import SlideUp from '../../components/animation/SlideUp';
import {setOssUrl} from "@/utils/methods/_common"

function handleData(list) {
  return (list || []).map(item => ({
    id: item.fid,
    title: item.ftitle,
    img: setOssUrl(item.fmainpic),
    time: parseTime(item.fsubmittime, 'YYYY/MM/DD'),
    content: item.fabstract,
    videoUrl: setOssUrl(item.fvideo),
  }))
}

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [],
      industryList: [],
      videoList: [],
      isLoading_company: true,
      isLoading_industry: true,
      isLoading_video: true
    };
  }
  componentDidMount() {
    infoService.dynamicList({pageNo: 1, pageSize: 7}).then(res => {
      this.setState({
        companyList: handleData(res.list)
      });
    }).finally(()=>{
      this.setState({isLoading_company: false});
    });
    infoService.informationList({pageNo: 1, pageSize: 3}).then(res => {
      this.setState({
        industryList: handleData(res.list),
      });
    }).finally(()=>{
      this.setState({isLoading_industry: false});
    });
    infoService.videoList({pageNo: 1, pageSize: 3}).then(res => {
      this.setState({
        videoList: handleData(res.list),
      });
    }).finally(()=>{
      this.setState({isLoading_video: false});
    });
  }

  goDetailsPage = (item, type) => {
    this.props.history.push(`/news/details/${type}/${item.id}`);
  }

  render() {
    let companyList_top = this.state.companyList.slice(0, 4);
    let companyList_bottom = this.state.companyList.slice(4);
    return (
        <div className={css["dj-news"]}>
          <div className={css['dj-news-content']}>
            <Block title="企业动态" link="/news/enterpriseNews">
              <Spin spinning={this.state.isLoading_company}>
                {
                  companyList_top.length && (
                      <SwiperItem className={css['enterpriseNews-swiper']} list={companyList_top} onDetail={item=>this.goDetailsPage(item, enums.blocks.dynamic.value)}></SwiperItem>
                  ) || ''
                }
                {
                  companyList_bottom.length && (
                      <ItemGroup layout={[[32, 32, 32]]}>
                        {companyList_bottom.map((item, index) => (
                            <SlideUp key={index} delay={index * 200}>
                              <Item key={index} item={item} onClick={()=>this.goDetailsPage(item, enums.blocks.dynamic.value)}></Item>
                            </SlideUp>
                        ))}
                      </ItemGroup>
                  ) || ''
                }
              </Spin>
            </Block>
            {/*{*/}
              {/*this.state.companyList.length && (*/}
                  {/*<Block title="企业动态" link="/news/enterpriseNews">*/}
                    {/*<SwiperItem className={css['enterpriseNews-swiper']} list={companyList_top} onDetail={item=>this.goDetailsPage(item, enums.blocks.dynamic.value)}></SwiperItem>*/}
                    {/*{*/}
                      {/*companyList_bottom.length ? (*/}
                          {/*<ItemGroup layout={[[32, 32, 32]]}>*/}
                            {/*{companyList_bottom.map((item, index) => (<Item key={index} item={item} onClick={()=>this.goDetailsPage(item, enums.blocks.dynamic.value)}></Item>))}*/}
                          {/*</ItemGroup>*/}
                      {/*) : ''*/}
                    {/*}*/}
                  {/*</Block>*/}
              {/*) || ''*/}
            {/*}*/}
            <Block title="行业资讯" link="/news/industryInformation">
              <Spin spinning={this.state.isLoading_industry}>
                <ItemGroup layout={[[32, 32, 32]]}>
                  {this.state.industryList.map((item, index) => (
                      <SlideUp key={index} delay={index * 200}>
                        <Item key={index} item={item} onClick={()=>this.goDetailsPage(item, enums.blocks.information.value)}></Item>
                      </SlideUp>
                  ))}
                </ItemGroup>
              </Spin>
            </Block>
            <Block title="视频中心" link="/news/videosCenter">
              <Spin spinning={this.state.isLoading_video}>
                <ItemGroup layout={[[32, 32, 32]]}>
                  {this.state.videoList.map((item, index) => (
                      <SlideUp key={index} delay={index * 200}>
                        <Item key={index} item={item} type="video"></Item>
                      </SlideUp>
                  ))}
                </ItemGroup>
              </Spin>
            </Block>
          </div>
        </div>
    );
  }
}

export default withRouter(News);
