/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-16 17:44:35
 * @LastEditTime: 2021-02-21 14:02:50
 * @LastEditors: dingxuejin
 */

import { PureComponent as Component } from "react";
import css from './index.module.scss';
class Refrash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: ""
        };
    }
    componentDidMount() {
        setTimeout(() => {
            this.props.onRef(this)
            this.createCode()
            this.props.onCode(this.state.code);
        })
    }
    createCode = () => {
        var code = "";
        var codeLength = 4; //验证码的长度   
        var checkCode = document.getElementById("checkCode");
        var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
            'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'); //随机数   
        for (var i = 0; i < codeLength; i++) { //循环操作   
            var charIndex = Math.floor(Math.random() * 36); //取得随机数的索引 
            code += random[charIndex]; //根据索引取得随机数加到code上   
        }

        checkCode.value = code; //把code值赋给验证码 
        this.setState({
            code: code
        })
    }

    refrash = () => {
        this.createCode()
        setTimeout(() => {
            this.props.onCode(this.state.code);
        });

    }

    render() {
        return (
            <div className={css["refrash"]}>
                <div className={css["img"]}>
                    <input id="checkCode" className={css["Code"]} />
                </div>
                <div className={css["ref"]} onClick={this.refrash}>
                    <div className={css["tex"]}>刷新</div>
                    <div className={css["ico"]}></div>
                </div>
            </div >
        );
    }
}

export default Refrash;