/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-07 20:31:06
 * @LastEditors: dingxuejin
 */
import React, {PureComponent as Component} from "react";
import css from './index.module.scss'
import LineItem from '../../components/LineItem'
import infoService from '../../../../api/service/info'
import {parseTime} from '../../../../utils/methods';
import enums from '../../../../utils/enum';
import {addScrollBottomListener, removeScrollBottomListener} from '../../../../utils/methods';
import { withRouter } from 'react-router-dom'
import { Spin } from 'antd';
import {setOssUrl} from "@/utils/methods/_common"

function handleData(list) {
  return (list || []).map(item => ({
    id: item.fid,
    title: item.ftitle,
    img: setOssUrl(item.fmainpic),
    time: parseTime(item.fsubmittime, 'YYYY-MM-DD'),
    subTitle: item.fabstract,
  }))
}

class EnterpriseNews extends Component {
  constructor(props) {
    super(props);
    this.pageNo = 1;
    this.total = 0;
    this.state = {
      list: [],
      isLoading: true,
    };
    this.content = React.createRef();
  }

  componentDidMount() {
    // this.getAllList();
    this.getList();
    this.scrollLoad();
  }

  componentWillUnmount () {
    removeScrollBottomListener(window, this.content.current, this.scrollFn);
  }

  scrollLoad = () => {
    addScrollBottomListener(window, this.content.current, this.scrollFn)
  };

  scrollFn = (e, bool) => {
    if (!bool) return;
    if (this.state.isLoading || this.total <= this.state.list.length) return;
    this.pageNo ++;
    this.getList();
  };

  getList = () => {
    this.setState({isLoading: true});
    infoService.dynamicList({pageNo: this.pageNo, pageSize: 10}).then(res => {
      this.total = res.total;
      this.setState(state=> {
        return {
          list: state.list.concat(handleData(res.list))
        }
      });
    }).finally(() => {
      this.setState({isLoading: false});
    });
  }

  // getAllList = () => {
  //   this.setState({isLoading: true});
  //   infoService.dynamicList({pageNo: 1, pageSize: 9999999}).then(res => {
  //     this.setState({
  //       list: handleData(res.list)
  //     });
  //   }).finally(() => {
  //     this.setState({isLoading: false});
  //   });
  // }

  goDetailsPage = (item, type = enums.blocks.dynamic.value) => {
    this.props.history.push(`/news/details/${type}/${item.id}`);
  }

  render() {
    let {list = [], isLoading} = this.state;
    return (
        <div className={css["dj-enterprise-news"]}>
          <div ref={this.content} className={css["dj-enterprise-news-content"]}>
            {
              list.map((item, index) => (
                  <LineItem item={item} key={index} onClick={()=>this.goDetailsPage(item)}></LineItem>
              ))
            }
            {
              isLoading && (
                  <div className={css['loading']}>
                    <Spin />
                  </div>
              ) || ''
            }
          </div>
        </div>
    );
  }
}

export default withRouter(EnterpriseNews);
