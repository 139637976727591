/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-07 20:31:18
 * @LastEditors: dingxuejin
 */
import { PureComponent as Component } from "react";

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="dj-product">
                产品中心
            </div >
        );
    }
}

export default Product;
