/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-07 20:25:50
 * @LastEditors: dingxuejin
 */
import {PureComponent as Component} from "react";
import { withRouter } from 'react-router-dom'
import Block from '../news/components/Block'
import ItemGroup from '../news/components/ItemGroup'
import Item from './components/Item'
import HoverItem from './components/HoverItem'
import css from './index.module.scss'
import empower_1 from '@/assets/images/case/empower_1.svg';
import empower_2 from '@/assets/images/case/empower_2.svg';
import empower_3 from '@/assets/images/case/empower_3.svg';
import empower_4 from '@/assets/images/case/empower_4.svg';
import case_1 from '@/assets/images/case/case_1.jpg';
import case_2 from '@/assets/images/case/case_2.jpg';
import case_3 from '@/assets/images/case/case_3.jpg';
import case_4 from '@/assets/images/case/case_4.jpg';

class Case extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advantages: [
        {
          img: empower_1,
          title: '全流程评测，行业领先的大数据诊断分析',
          content: '通过对全国800多家纸板企业经营现状分析，纸板企业户基于自身运营情况，进行15项综合模块现状确认。大数据自动生成全流程诊断结果及相应整改建议。生产经营细节全掌握，让管理中的“疑难杂症”无处可逃。'
        },
        {
          img: empower_2,
          title: '易纸板赋能，突破性全流程痛点解决方案',
          content: '整合智能营销、原纸赋能、物流赋能、自动技术升级、管理赋能五大功能，实现对纸板厂营销、管理、物流、技术的全流程赋能。突破性的全流程痛点解决方案，支持纸板厂生产运营工作，规范企业流程，大幅提升企业运行效率。'
        },
        {
          img: empower_3,
          title: '业内顶尖管理专家助阵，定制解决方案',
          content: '结合工厂运营情况，业内顶尖管理专家团队驻厂指导。梳理打通纸板厂各环节业务流程，监督助推供应商内部制度落地，为合作工厂提供智能化系统对接、完善企业管理制度等一系列赋能服务，击破纸板厂在生产运营过程中遇到的所有痛点。'
        },
        {
          img: empower_4,
          title: '近 30 年行业经验，深耕行业、更懂市场',
          content: '东经从事纸包装行业近30年，深耕行业、更懂市场，通过全流程测评、全方位赋能、全领域运营，帮助纸包装企业提升生产工艺和管理水平，实现降本、提效、增值。'
        },
      ],
      parters: [
        {
          id: 1,
          img: case_1,
          name: '浙江神泰包装股份有限公司',
          intro: '2018年9月与东经合作，经过双方团队共同努力，实现日均接单量提升221.4%，月下单客户家数提升55%，平均车速提升40%，交付达成率提升30.99%，客诉率下降85.8%，利润成倍增长',
        },
        {
          id: 2,
          img: case_2,
          name: '德州久茂包装科技有限公司',
          intro: '2021年5月与东经合作，经过双方团队共同努力实现日均接单量提升133%，生产产值提升168%，人均效能提升50%，生产损耗降低28%，客诉率降低20%…',
        },
        {
          id: 3,
          img: case_3,
          name: '天津市朋创工贸股份有限公司',
          intro: '2020年9月与东经合作，经过双方团队共同努力，实现供应商业务增长率58%，新增月活客户同比增加68%，72小时交付率达成99.39%，整体客诉率控制在3%以下',
        },
        {
          id: 4,
          img: case_4,
          name: '青田三本包装有限公司',
          intro: '2019年5月与东经建立合作关系，实现月活客户同比提升至400%，产品种类优化至21种，生产成本降低40%，生产平均车速同比增长20%，原纸利用率提升至96.37%，应收账款由之前60天左右，变为全额预付款',
        },
      ]
    };
  }

  render() {
    return (
        <div className={css['dj-case']}>
          <div className={css['dj-case-center']}>
            <Block title="赋能优势" noMore={true}>
              <ItemGroup layout={[[49, 49]]} colGap="24px" model="loop">
                {
                  this.state.advantages.map((item, index) => <Item item={item} key={index}></Item>)
                }
              </ItemGroup>
            </Block>
          </div>
          <div className={css['dj-case-whole']}>
            <div className={css['dj-case-center']}>
              <Block title="优秀合作伙伴" noMore={true}>
                <ItemGroup layout={[[49, 49]]} colGap="20px" model="loop">
                  {
                    this.state.parters.map(item =>
                        <HoverItem item={item}
                                   key={item.id}
                                   onClick={()=>this.props.history.push(`/case/caseParter/${item.id}`)}>
                        </HoverItem>
                    )
                  }
                </ItemGroup>
              </Block>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(Case);
