import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {parseClass, concatClass} from '../../../../utils/methods';
import css from './index.module.scss'

class ItemGroup extends PureComponent {
  constructor() {
    super();
  }

  static defaultProps = {
    colGap: '0',
    layout: []
  }

  static propTypes = {
    // 行间隔
    colGap: PropTypes.string,
    // 容器类名
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func, PropTypes.array]),
    // 行类名
    rowClass: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func, PropTypes.array]),
    // 列类名
    colClass: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func, PropTypes.array]),
    // 鼠标移入某一项事件回调
    onMouseEnter: PropTypes.func,
    // 鼠标移出某一项事件回调
    onMouseLeave: PropTypes.func,
    // 具体布局方式
    layout: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    // 布局模式： repeat：超出内容按layout最后一行的排列方式排列； loop：超出内容按layout循环排列；undefined：超出内容不展示
    model: PropTypes.oneOf(['repeat', 'loop', undefined]),
    // 布局内容
    children: PropTypes.array,
  }

  render() {
    let {colGap, className, onMouseEnter, onMouseLeave, rowClass, colClass} = this.props;
    let layout = this.getRealList();
    return (
        <div className={`${css['item-group']} ${className ? className : ''}`}>
          {
            layout.map((arr, index) => (
                <div className={concatClass(css['row'], parseClass(rowClass, index))} key={index} style={{marginTop: index && colGap}}>
                  {
                    arr.map((cfg, idx) => {
                      if (cfg.item) {
                        return (
                            <div className={concatClass(css['col'], parseClass(colClass, index, idx))}
                                 style={{width: cfg.width + '%'}}
                                 key={idx}
                                 onMouseEnter={e=>onMouseEnter&&onMouseEnter(e, cfg.index)}
                                 onMouseLeave={e=>onMouseLeave&&onMouseLeave(e, cfg.index)}>
                              {cfg.item}
                            </div>
                        );
                      } else if (cfg.width) {
                        return (<span className={css['divider']} key={idx} style={{width: cfg.width + '%'}}></span>)
                      }
                    })
                  }
                </div>
            ))
          }
        </div>
    );
  }

  getRealList = () => {
    let {layout = [], children = [], model} = this.props;
    let index = 0;
    let list = children;
    let length = children.length;
    let result = [];
    // 根据layout遍历一次生成的布局配置
    function getOnceList(layout = []) {
      return layout.reduce((_list, arr, rowIndex) => {
        if (length - 1 < index) return _list;
        let sum = arr.reduce((sum, num) => sum + num, 0);
        let divide = (100 - sum) / (arr.length - 1);
        _list[rowIndex] = arr.reduce((a, num, idx) => {
          if (idx) a.push({width: divide});
          a.push({width: num, item: list[index], index});
          index++;
          return a;
        }, []);
        return _list;
      }, []);
    }
    if (model === 'repeat') {
      while (length > index)  {
        result = result.concat(getOnceList([layout[layout.length - 1]]));
      }
    } if (model === 'loop') {
      while (length > index)  {
        result = result.concat(getOnceList(layout));
      }
    } else {
      result = getOnceList(layout);
    }
    return result;
  }
}

export default ItemGroup;