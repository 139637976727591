/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-14 14:39:29
 * @LastEditTime: 2021-02-19 17:40:09
 * @LastEditors: dingxuejin
 */

import { lazy, PureComponent as Component } from "react";
import css from './index.module.scss';
import ItemGroup from "../../news/components/ItemGroup";
import bottom_bg_product from "../../../assets/images/product/djNet/bottom_bg_product.png";

import industry_icon_cooperation from "@/assets/images/product/djNet/industry_icon_cooperation.svg"
import industry_icon_product from "@/assets/images/product/djNet/industry_icon_product.svg"
import industry_icon_programme from "@/assets/images/product/djNet/industry_icon_programme.svg"

const Con = lazy(() => import("../components/intro/index"));
const Item = lazy(() => import("../components/item/index"));

class DjNet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            introData: {
                bgImage: require("@/assets/images/product/djNet/card_bg_yiwang.png").default,
                text: [
                  "中国领先的包装产业互联网平台，包装产业数字化解决方案服务商。",
                    "纸包装行业上下游企业的经营百宝箱，9大类目覆盖产业供应全链路经营需求，为包装产业链各环节提供全流程、数字化、智能化服务，从而实现降低成本，提高效率，增加价值。"
                ]
            },
            layoutMap: {
                '-1': [[50, 50]],
                0: [[66, 34],[50, 50]],
                1: [[34, 66],[50, 50]],
                2: [[50, 50],[66,34]],
                3: [[50, 50],[33,67]],
            },
            active: -1
        };
    }

    onEnter = (e, index) => {
        this.setState({
            active: index
        })
    }

    onLeave = (e, index) => {
        this.setState({
            active: -1
        })
    }

    render() {
        let {layoutMap, active} = this.state;
        return (
            <div className={css["djNet"]}>
                <Con introData={this.state.introData}></Con>
                <Item title="行业痛点">
                    <div className={css["con"]}>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>业务线分散</div>
                            <div className={css["des"]}>产品种类多、订单离散化，导致生产效率降低</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>运营不规范</div>
                            <div className={css["des"]}>生产成本无法有效控制，产品质量很难保障</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>下单流程不规范</div>
                            <div className={css["des"]}>电话传真等传统下单方式，出错率高、时效低</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>资金周转压力大</div>
                            <div className={css["des"]}>45-90天的超长账期，制约着企业的经营发展</div>
                        </div>
                    </div>
                </Item>
                <Item title="全流程赋能解决方案" bgcolor="#f5f5f5">
                    <ItemGroup layout={layoutMap[active]} className={css['item-group']} onMouseEnter={this.onEnter} onMouseLeave={this.onLeave} model="loop">
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>产品整合</div>
                            <div className={css["des"]}>通过对终端的调研、分析，准确把握市场真是需求，实现种类整合。如将温州市场100多个品种整合为18个，满足市场80%的需求。</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>精细化生产管理</div>
                            <div className={css["des"]}>产品种类整合及精细化生产管理并举，提高订单集中度和单一种类产量，提升生产效率；降低生产过程中损耗，降低生产成本。</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>线上高速无错误下单</div>
                            <div className={css["des"]}>用手机APP线上下单的方式替代了原有的电话、传真下单，提升了用户体验，提高了下单效率，降低了出错率。订单差错率下降98%、接单人员减少90%、重复订单5秒内即可完成下单。</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>0应收账款</div>
                            <div className={css["des"]}>解决方案：以质优价廉的产品服务客户，以此为基础：用“预付款”取代传统的“账期”，实现0应收账款，缓解企业的资金周转压力；并与银行合作，推出供应链金融服务，为企业发展提供金融支持。</div>
                        </div>
                    </ItemGroup>
                </Item>
                <Item title="覆盖产业供应全链路" color="#fff">
                    <div className={css["lst"]}>
                        <ItemGroup layout={[[32,32,32]]}>
                            <div className={css["itm"]}>
                                <div className={css["ico"]} style={{backgroundImage: `url(${industry_icon_cooperation})`}}></div>
                                <div className={css["inf"]}>
                                    <div className={css["num"]}>2W+</div>
                                    <div className={css["des"]}>纸包装企业合作</div>
                                </div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]} style={{backgroundImage: `url(${industry_icon_product})`}}></div>
                                <div className={css["inf"]}>
                                    <div className={css["num"]}>4大维度</div>
                                    <div className={css["des"]}>产品中心</div>
                                </div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]} style={{backgroundImage: `url(${industry_icon_programme})`}}></div>
                                <div className={css["inf"]}>
                                    <div className={css["num"]}>100+</div>
                                    <div className={css["des"]}>行业解决方案</div>
                                </div>
                            </div>
                        </ItemGroup>
                    </div>
                </Item>
                <Item title="平台服务类目">
                    <div className={css["con-1"]}>
                        <div className={css["lef"]}>
                            <div className={css["titl"]}>东经易网产品服务</div>
                            <div className={css["des"]}>为纸包装上下游企业提供原材料、辅料、设备等采购服务的同时，提供管理系统、管理咨询、资源对接等定制化服务</div>
                        </div>
                        <div className={css["lst"]}>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["tet"]}>原纸贸易</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["tet"]}>纸板团购</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["tet"]}>纸箱集市</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["tet"]}>包装辅料</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["tet"]}>赋能产品</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["tet"]}>金融服务</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["tet"]}>供需匹配</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["tet"]}>资源对接</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["tet"]}>行业资讯</div>
                            </div>
                        </div>
                    </div>
                    <div className={css["con-2"]}>
                        <div className={css["tex"]}>覆盖全产业链上下游 解决纸包装企业全流程需求</div>
                        <a className={css["btn"]} href="https://www.djcps.com/" target="_blank">进入平台</a>
                    </div>
                </Item>
                <Item>
                    <div>
                        <div className={css["bg"]} >
                            <img src={bottom_bg_product} alt=""/>
                        </div>
                        <div className={css["code"]}>
                            <div className={css["img"]}></div>
                            <div className={css["tet"]}>下载东经易网APP</div>
                        </div>
                    </div>
                </Item>
            </div >
        );
    }
}

export default DjNet;
