/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-18 23:15:37
 * @LastEditors: dingxuejin
 */
import React, {lazy, PureComponent as Component, useState, useEffect, useRef} from "react";
import { connect } from "react-redux";
import home_data_icon_promote from '@/assets/images/home/home_data_icon_promote.svg';
import home_data_icon_reduce from '@/assets/images/home/home_data_icon_reduce.svg';
import home_data_icon_utilize from '@/assets/images/home/home_data_icon_utilize.svg';
import home_data_icon_reach from '@/assets/images/home/home_data_icon_reach.svg';
import './index.scss';
import ItemGroup from '../../../news/components/ItemGroup';
import {useElScrollToBottom} from '../../../../hooks';

import {getTransition} from "../../../../utils/methods";
import productService from "../../../../api/service/product";

import {setOssUrl} from "@/utils/methods/_common"

const Panel = lazy(() => import("./panel"));
const SliderPanel = lazy(() => import("./sliderPanel"));

const transition = getTransition();

function DigCoo(props) {
    let {isMobile} = props;
    const sliderPanel = useRef();
    let [index, setIndex] = useState(0);
    let [nav, setNav] = useState([]);
    let [panelData, setPanelData] = useState({});
    let [to, setTo] = useState("");
    let [cooperationResult, setCooperationResult] = useState([0, 0, 0, 0]);
    let {ref} = useElScrollToBottom(numberAddAnimation);

    useEffect(() => {
        getReleaseProductList();
    }, []);

    // 获取所有产品
    function getReleaseProductList() {
        productService.getReleaseProductList().then(res => {
            let data = [], panelObj = {}, to = "";
            for (let i of res.list) {
                let path = "";
                if (i.domain.includes("http")) {
                    path = i.domain;
                } else {
                    path = "http://" + i.domain;
                }
                data.push({
                    des: i.productName,
                    active: false,
                    path: path,
                    panelData: {
                        ico: "",
                        des: i.productName,
                        text1: i.description,
                        text2: "",
                        fix: i.position,
                        rig: setOssUrl(i.img)
                    }
                });
            }
            if (data.length > 0) {
                data[0].active = true;
                panelObj = data[0].panelData;
                to = data[0].path;
            }
            setNav(data);
            setPanelData(panelObj);
            setTo(to);
        }).finally(() => {

        });
    }

    // tab切换
    function change(index) {
        let _nav = [...nav];

        _nav.forEach((item, i) => {
            if (index == i) {
                item.active = true;
            } else {
                item.active = false;
            }
        });

        setIndex(index);
        setNav(_nav);
        setPanelData(_nav[index].panelData);
        setTo(_nav[index].path);

        if (isMobile) {
            sliderPanel.current.goByIndex(index);
        }
    }

    // 数字累加动画方法
    function numberAddAnimation() {
        transition([0, 0, 0, 0], [30, 70, 3.87, 96], (...argv) => {
            setCooperationResult(argv.map((v, idx) => idx === 2 ? v.toFixed(2) : Math.ceil(v)));
        }, 1000);
    }

    function onProductChange(index) {
        if (index < 0) index += nav.length;
        if (index > nav.length - 1) index -= nav.length;
        change(index);
    }

    return (
        <div className="dj-home-digCoo">
            <div className="dj-home-dig">
                <div>
                    <div className="dj-home-dig-titl">数字化全场景产品及服务</div>
                    <div className="dj-home-dig-dec">
                        <p>"包装+互联网" 模式打造中小企业降本提效，价值倍增包装供应链服务平台，实现全产业链各利益相关方共赢。</p>
                        {/*<p>实现全产业链各利益相关方共赢。</p>*/}
                    </div>
                </div>
            </div>
            {nav.length > 0 && <div className="dj-home-busi">
                <div className="dj-home-bus-warp">
                    <div className="dj-home-busi-tab">
                        <ul>
                            {nav.map((item, index) => {
                                return (<li className={item.active ? "active" : ""} onClick={() => change(index)}
                                            key={index}><i>{item.des}</i></li>);
                            })}
                        </ul>
                        {/*<Link to={this.state.to} className="all">查看全部＞</Link>*/}
                    </div>
                    {
                        isMobile ?
                          (<SliderPanel
                            ref={sliderPanel}
                            panelData={panelData}
                            allPanelData={nav}
                            to={to}
                            onPrev={() => onProductChange(--index)}
                            onNext={() => onProductChange(++index)}></SliderPanel>) :
                          (<Panel panelData={panelData} allPanelData={nav} to={to}></Panel>)
                    }
                </div>
            </div>}
            <div className="dj-home-dig">
                <div>
                    <div className="dj-home-dig-titl">合作成果</div>
                    <div className="dj-home-dig-dec">
                        <p>东经先后与125家纸板厂达成合作，助力其业绩高效增长，企业健康发展</p>
                    </div>
                </div>
            </div>
            <div className="dj-home-data">
                <div>
                    <div ref={ref}></div>
                    <ItemGroup layout={ props.isMobile ? [[50, 50], [50, 50]] :[[25, 25, 25, 25]]}>
                        <div className="dj-home-data-ite">
                            <div className="dj-home-data-ite-ico"
                                 style={{backgroundImage: `url(${home_data_icon_promote})`}}></div>
                            <div className="dj-home-data-ite-num">{cooperationResult[0]}%+</div>
                            <div className="dj-home-data-ite-dec">区域市场占有率提升</div>
                        </div>
                        <div className="dj-home-data-ite">
                            <div className="dj-home-data-ite-ico"
                                 style={{backgroundImage: `url(${home_data_icon_reduce})`}}></div>
                            <div className="dj-home-data-ite-num">{cooperationResult[1]}%</div>
                            <div className="dj-home-data-ite-dec">生产成本最高降低</div>
                        </div>
                        <div className="dj-home-data-ite">
                            <div className="dj-home-data-ite-ico"
                                 style={{backgroundImage: `url(${home_data_icon_utilize})`}}></div>
                            <div className="dj-home-data-ite-num">{cooperationResult[2]}%</div>
                            <div className="dj-home-data-ite-dec">原纸利用率上升</div>
                        </div>
                        <div className="dj-home-data-ite">
                            <div className="dj-home-data-ite-ico"
                                 style={{backgroundImage: `url(${home_data_icon_reach})`}}></div>
                            <div className="dj-home-data-ite-num">{cooperationResult[3]}%</div>
                            <div className="dj-home-data-ite-dec">36小时交期达成率</div>
                        </div>
                    </ItemGroup>
                </div>
            </div>
        </div>
    );
}

export default connect(state => ({isMobile: state.getIn(['app', 'isMobile'])}))(DigCoo);
