/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-07 20:25:50
 * @LastEditors: dingxuejin
 */
import {Component} from "react";
import Block from '../../../news/components/Block'
import ItemGroup from '../../../news/components/ItemGroup'
import Tip from './components/Tip'
import Item from './components/Item'
import Question from './components/Question'
import css from './index.module.scss'
import { withRouter } from 'react-router-dom'
import story_img_case1 from '@/assets/images/case/story_img_case1.jpg';
import story_img_case2 from '@/assets/images/case/story_img_case2.jpg';
import story_img_case3 from '@/assets/images/case/story_img_case3.jpg';
import story_img_case4 from '@/assets/images/case/story_img_case4.jpg';
import title_case1 from '@/assets/images/case/title_case1.jpg';
import title_case2 from '@/assets/images/case/title_case2.jpg';
import title_case3 from '@/assets/images/case/title_case3.jpg';
import title_case4 from '@/assets/images/case/title_case4.jpg';

class Case extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        1: {
          header: {
            img: title_case1,
            name: '浙江神泰包装股份有限公司',
            intro: '2018年9月与东经合作，经过双方团队共同努力，实现日均接单量提升221.4%，月下单客户家数提升55%，平均车速提升40%，交付达成率提升30.99%，客诉率下降85.8%，利润成倍增长',
          },
          story: {
            img: story_img_case1,
            intro: '“与东经合作后，大大降低了公司内耗，实现现金流，这才是合作双赢！这个决定，无疑是正确的！”5月15日，东经科技执行总裁李林潼带队一行人亲赴嘉兴走访客户。期间，拜访嘉兴神泰包装有限公司时，总经理张一鹏聊谈起与东经合作以来的变化，一席话振奋了在场所有人。',
            list: [
              {
                title: '跟东经易网之后有什么变化？',
                content: '生产方面，东经输出了专项整改方案，完成了包括生产少数，司机质量培训、规范生产损耗、设备保养、专项生产质量问题、专项设备问题等几大模块的整改。材质整合后，产能得到了极大的提升，平均车速也快速上升；销售方面，对客户实行精细化管理，持续的产品结构优化，全面提升接单量与单品接单量；运营方面，策划大型活动并根据客户给予针对性的优惠政策，规范客诉处理，落地大客服体系，大大提升用户体验，增强了客户粘性；物流方面，实行全面的司机管控，开展司机人员质量培训、建立服务标准，全面提升交付率。'
              },
              {
                title: '怎么看待在东经易网平台上的前景和整个行业的前景？',
                content: '因业务迅速拓展，神泰包装已启动新厂建设，总投资1.7亿，总面积75000平方，预计今年7月结顶，12月份竣工。神泰包装负责人张总表示，新厂房新起点，期待优质三级厂伙伴入驻，共同迈向新高度！\n走访期间，东经科技执行总裁李林潼，嘉兴分公司供应中心团队及神泰包装负责人针对生产、物流等方面存在的“顽疾”进行深入分析，共同商讨解决方案，制定进一步的行动计划。相信未来，神泰包装携手东经将实现更大的飞跃！'
              }
            ]
          },
          experts: [
            '协助合作纸板厂人员招聘替换，完成人力保障',
            '结合工厂运营情况，梳理内部流程制度',
            '定时组织召开专项分析研讨会，对标输出整改方案',
            '监督助推内部考核制度落地',
          ],
          cooperationData: [
            {
              title: '原纸利用率',
              content: '由92%提升至95.56%，同比上升3.87%'
            },
            {
              title: '纸板修边率',
              content: '由4%下降至2.56%，同比下降36%'
            },
            {
              title: '生产车速',
              content: '由105提升至150，同比上升42.86%'
            },
            {
              title: '订单72小时交付率',
              content: '由71%提升至93.30%，同比上升31.41%'
            },
            {
              title: '客诉率',
              content: '由4.93%下降至0.71%，同比下降85.6%'
            },
          ]
        },
        2: {
          header: {
            img: title_case2,
            name: '德州久茂包装科技有限公司',
            intro: '2021年5月与东经合作，经过双方团队共同努力，实现日均接单量提升133%，生产产值提升168%，人均效能提升50%，生产损耗降低28%，客诉率降低20%，交付率提升248%',
          },
          story: {
            img: story_img_case2,
            intro: '如果纸板厂在经营各环节上没有进行合理的管理，必将导致生产效率低、入库率低、物流交付率低等问题，其中每一个环节产生的成本，就是一大笔数字！长此以往，那纸板厂的竞争优势将不复存在。面对如此困境，很多纸板厂寻求数字化转型，为企业注入发展“新动力”，而德州久茂包装科技有限公司就是其中之一。',
            list: [
              {
                title: '为什么和东经合作？',
                content: '高总：“东经拥有丰富纸包装行业整体包装解决方案实践经验，对纸包装客户及供应商的痛点具有深度理解，从而才能打造出一整套支持商业模式运营的数字化、智能化管理系统，帮助我们真正的实现降本，提效，增值。”'
              },
              {
                title: '跟东经合作后提升了什么？',
                content: '5月份和东经签订了合作协议，并于6月1号正式成立联营工厂投入生产，合作之后东经从资金、人员、技术、数字化平台等多个方面给予了全力支持，对其管理、销售、采购、生产、物流五大核心环节提供全流程、数字化、智能化技术赋能服务。\n' +
                    '其中，数字化平台-东经易网为客户节省了下单时间并避免了下单错误，客户可以通过该APP随时查询订单生产进度、入库数量、送货进度，并随时可以对账和反馈产品质量，为客户节省了时间和人力。双方合作仅半年时间，公司整个管理上了一个新台阶，员工的精神面貌有了很大的改变。'
              },
              {
                title: '跟东经合作之后有什么突破？',
                content: '德州久茂包装与东经易网正式达成深度合作后，双方对此合作高度重视，针对痛点和应用场景，相关模块人员快速对接，迅速推进人员组建、制度梳理、系统产品落地等工作，使德州久茂包装成为山东第一家数字化示范工厂。\n' +
                    '2021年10月蒋董事长来公司考察，对公司未来的发展寄予厚望，并把公司的名字改为东经科技（德州）公司，接下来要把总部的整个数字化运营生产模式在东经科技（德州）公司快速复制落地。 '
              }
            ]
          },
          experts: [
            '市场调研分析，订单材质整合，制定营销策略开拓新市场',
            '人资专家入厂帮扶，梳理内部流程制度',
            '生产专家入厂帮扶，明确各岗位职能职责，降低各项损耗',
            '仓储物流专家入厂帮扶，提升仓储运转效率，增加物流运力',
            '定时组织召开专项分析研讨会，对标输出整改方案'
          ],
          cooperationData: [
            {
              title: '日均接单',
              content: '提升133%'
            },
            {
              title: '市场数据',
              content: '新增客户402家'
            },
            {
              title: '生产产值',
              content: '提升168%'
            },
            {
              title: '人均效能',
              content: '提升50%'
            },
            {
              title: '生产车速',
              content: '提升77%'
            },
            {
              title: '生产损耗',
              content: '降低28%'
            },
            {
              title: '24小时准时入库率',
              content: '提升至96.7%'
            },
            {
              title: '客诉率',
              content: '降低20%'
            },
            {
              title: '交付率',
              content: '提升248%'
            },
          ]
        },
        3: {
          header: {
            img: title_case3,
            name: '天津市朋创工贸股份有限公司',
            intro: '2020年9月与东经合作，经过双方团队共同努力，实现供应商业务增长率58%，新增月活客户同比增加68%，72小时交付率达成99.39%，整体客诉率控制在3%以下',
          },
          story: {
            img: story_img_case3,
            intro: '随着疫情好转后市场经济的快速发展，国内纸包装大部分企业仍呈现不温不火的状态。纸板纸箱厂随纸价动态涨跌起伏，接单量客户数也随之受影响。\n但是有一家企业20天内接单量与客户增长均呈上升趋势，从一个月开机13天，做到了每天开机！这一切都是因为.....',
            list: [
              {
                title: '跟东经合作之后有什么突破？',
                content: '针对生产管理，东诚专家组完善了合作伙伴朋创的质量体系。并现场指导质检员对每日抽检出的不良品进行记录分析；对生产制程损耗记录方式、前端反馈的质量问题、生产线湿部与干部联动性问题进行沟通改善。\n' +
                    '改善前：仓库无规划，纸板摆放无规律，现场杂物摆放凌乱，导致找货难。\n' +
                    '改善后：完成仓库与物流对接流程梳理，针对仓库库区和货物摆放进行调整优化，提升整体仓库效能，降低找货时间50%。'
              },
              {
                title: '合作开启全新经营模式',
                content: '每家二级厂都是为中国包装行业发展做出过努力和贡献，而如何更好地发展、推广和改变包装行业现状，成为了所有包装行业工作者要认真思考的问题。\n' +
                    '东经易网凭借多年包装+互联网的发展经验，秉承着打造中小企业降本提效、价值倍增的包装供应链服务平台为目标，多年来帮助众多中小企业实现降本提效和增值。这一次的"东朋握手“合作不仅是东经为合作伙伴朋创带来更好的发展，在合作的背后，更是东经与朋创一起携手实现互利共赢。'
              }
            ]
          },
          experts: [
            '市场调研分析，订单材质整合，制定营销策略开拓新市场',
            '梳理打通各环节业务流程，完善各模块数据表单信息记录',
            '定时组织召开专项分析研讨会，对标输出整改方案',
            '仓储物流专家驻厂帮扶，提升仓储运转效率，增加物流运力',
          ],
          cooperationData: [
            {
              title: '新增业务量',
              content: '日均5.8万平方，业务增长率58%'
            },
            {
              title: '市场数据',
              content: '新增月活客户64家'
            },
            {
              title: '72小时交付率',
              content: '交付率达成99.39%'
            },
            {
              title: '客诉率',
              content: '控制在3%以下'
            },
          ]
        },
        4: {
          header: {
            img: title_case4,
            name: '青田三本包装有限公司',
            intro: '2019年5月与东经建立合作关系，实现月活客户同比提升至400%，产品种类优化至21种，生产成本降低40%，生产平均车速同比增长20%，原纸利用率提升至96.37%，应收账款由之前60天左右，变为全额预付款',
          },
          story: {
            img: story_img_case4,
            intro: '纸包装行业传统业务模式主要是被动迎合市场多样化的非标需求，供应商普遍面临着成本高、交期慢、订单散、杂、少等难题，经营效益很难提升。作为这个市场的新玩家，青田三本包装面临的挑战尤其突出，东经易网又是怎样利用“包装+互联网”模式帮助供应商打破这一僵局的呢？今天我们走进青田三本包装，听听郭总怎么说。',
            list: [
              {
                title: '跟东经合作之后有什么变化？',
                content: '郭总：我们从去年青田三本新建成以来，一开始产品管理整体很乱，没有规范性，属于家庭作坊式的，到今年5月份开始合作，东经给我们在技术管理上面提升了很多，整体的规范性，我们现在整体按照东经的管理模式引进很多的管理方案，包括设备、整改、机修、维护，到最后的送货服务跟质量跟踪都给我们提供了很大的帮助。'
              },
              {
                title: '怎么看与东经合作的前景和整个行业的前景？',
                content: '郭总：跟东经合作的方式我是非常的认可，我们公司主推的是三层板，目前针对温州地区的三层板，按照我的了解，我非常有信心，不能说做到温州最好，但我可以做的更好，包括综合的性价比，我们的产能，设备的结构；我接触三层板将近10来年，青田三本是去年新建的，在（这个）的基础上，我们现在的性价比得到大家的一致认可，我对未来充满信心，加上跟东经合作我更加有信心。\n对于整个行业的前景我也是非常的看好，因为包装行业目前在物流方面，包括国家对相关环保的重抓，包括塑料包装、纸张包装这一块，我个人感觉、个人观点包装行业永远会是阳光产业。'
              }
            ]
          },
          experts: [
            '客户需求调研分析，整合产品材质，制定营销策略开拓市场',
            '结合工厂运营情况，梳理优化内部流程制度',
            '组建生产班会、管理班会，专项分析现状困难出具解决方案',
            '仓库重新规划，建立物流管理管理制度',
            '建立客诉处理制度，提高用户满意度',
          ],
          cooperationData: [
            {
              title: '产品种类优化',
              content: '产品种类21种'
            },
            {
              title: '市场数据',
              content: '月活客户500家'
            },
            {
              title: '成本控制',
              content: '降低生产成本40%'
            },
            {
              title: '生产平均车速',
              content: '由105米/分提升至126米/分'
            },
            {
              title: '原纸利用率',
              content: '提升至96.37%'
            },
            {
              title: '财务结构优化',
              content: '应收账款实现了全额预付款'
            },
          ]
        },
      }
    };
  }

  render() {
    let id = this.props.match.params.id;
    let data = this.state.data[id];
    let {story, header} = this.state.data[id];
    return (
        <div className={css['dj-case']}>
          <div>
            <div className={`${css['dj-case-center']} ${css['header']}`}>
              <ItemGroup layout={[[49, 47]]} model="loop">
                <div className={css['header-left']}>
                  <p className={css['header-left-name']}>{header.name}</p>
                  <p className={css['header-left-intro']}>{header.intro}</p>
                </div>
                <div className={css['header-right']}>
                  <img className={css['header-right-img']} src={header.img} alt=""/>
                </div>
              </ItemGroup>
            </div>
          </div>
          <div className={`${css['dj-case-center']} ${css['story']}`}>
            <Block title="客户故事" noMore={true}>
              <ItemGroup layout={[[49, 47]]} model="loop">
                <div className={css['story-left']}>
                  <img className={css['story-left-img']} src={story.img} alt=""/>
                  <div className={css['story-left-intro']}><pre>{story.intro}</pre></div>
                </div>
                <div className={css['story-right']}>
                  {
                    story.list.map(item => <Question que={item.title}>{item.content}</Question>)
                  }
                </div>
              </ItemGroup>
            </Block>
          </div>
          <div className={`${css['dj-case-whole']} ${css['experts']}`}>
            <div className={css['dj-case-center']}>
              <Block title="专家团队入驻" noMore={true}>
                <ItemGroup layout={[[49, 49]]} colGap="24px" model="loop">
                  {
                    data.experts.map((item, index) => <Tip index={index + 1}>{item}</Tip>)
                  }
                </ItemGroup>
              </Block>
            </div>
          </div>
          <div className={`${css['dj-case-whole']} ${css['cooperation']}`}>
            <div className={css['dj-case-center']}>
              <p className={css['cooperation-title']}>合作数据</p>
              <p className={css['cooperation-sub-title']}>基于专业性全流程诊断，提供管理咨询，生产帮扶等服务，目前东经已先后与125家纸板厂达成合作</p>
              <ItemGroup layout={[[32, 32, 32]]} colGap="24px" model="loop">
                {
                  data.cooperationData.map(item => <Item item={item}></Item>)
                }
              </ItemGroup>
            </div>
          </div>
          <div className={`${css['dj-case-whole']} ${css['link']}`}>
            <div className={css['dj-case-center']}>
              <p className={css['link-title']}>联系我们，解决您纸板厂的经营难题</p>
              <button className={css['link-btn']}
                      onClick={()=>this.props.history.push(`/contact/join`)}>
                联系我们
              </button>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(Case);
