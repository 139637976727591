/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:26:25
 * @LastEditTime: 2021-02-17 15:59:01
 * @LastEditors: dingxuejin
 */
import about from "./about";
import home from "./home";
import product from "./product";
import caseCenter from "./caseCenter";
import news from "./news";
import contact from "./contact";
import notFound from "./notFound";


export default [
    home,
    about,
    product,
    caseCenter,
    news,
    contact,
    notFound
]
