/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-07 20:31:06
 * @LastEditors: dingxuejin
 */
import {PureComponent as Component} from "react";
import ItemGroup from '../../components/ItemGroup'
import Item from '../../components/Item'
import AllBtn from '../../components/AllBtn'
import css from './index.module.scss'
import infoService from '../../../../api/service/info'
import {parseTime} from '../../../../utils/methods';
import { Spin } from 'antd';
import {setOssUrl} from "@/utils/methods/_common"
import videoIcon1 from "@/assets/images/news/video-icon1.png"
import videoIcon2 from "@/assets/images/news/video-icon2.png"
import noneIcon from "@/assets/images/news/none-icon.png"
import { connect } from "react-redux";

function handleData(list) {
  return (list || []).map(item => ({
    id: item.fid,
    title: item.ftitle,
    img: setOssUrl(item.fmainpic),
    time: parseTime(item.fsubmittime, 'YYYY/MM/DD'),
    content: item.fabstract,
    videoUrl: setOssUrl(item.fvideo),
  }))
}
@connect(state => ({isMobile: state.getIn(['app', 'isMobile'])}),)
class VideosCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      total: 0,
      isLoading: true,
      contentLabel: 'easy_box',
      contentLabelList: [
        {
          label: '易纸箱', //易纸箱:easy_box,易单来:easy_order,东经易网: group_on,易纸板: easy_board,企业风采: company_mien
          value: 'easy_box'
        },
        {
          label: '易单来',
          value: 'easy_order'
        },
        {
          label: '东经易网',
          value: 'group_on'
        },
        {
          label: '易纸板',
          value: 'easy_board'
        },
        {
          label: '企业风采',
          value: 'company_mien'
        },
      ]
    };
  }

  componentDidMount() {
    this.getFirstList();
  }

  getFirstList = () => {
    infoService.videoList({pageNo: 1, pageSize: 6, contentLabel: this.state.contentLabel}).then(res => {
      this.setState({
        list: handleData(res.list),
        total: res.total
      });
    }).finally(() => {
      this.setState({isLoading: false});
    });
  };

  getAllList = () => {
    this.setState({isLoading: true});
    infoService.videoList({contentLabel: this.state.contentLabel}).then(res => {
      this.setState({
        list: handleData(res.list)
      });
    }).finally(() => {
      this.setState({isLoading: false});
    });
  };

  getVideo = (str) => {
    this.setState({isLoading: true, list: [], total: 0, contentLabel: str});
    infoService.videoList({pageNo: 1, pageSize: 6, contentLabel: str}).then(res => {
      this.setState({
        list: handleData(res.list),
        total: res.total
      });
    }).finally(() => {
      this.setState({isLoading: false});
    });
  };

  render() {
    let {total, list = [], isLoading} = this.state;
    return (
        <div className={css["dj-videos-center"]}>
          { this.props.isMobile ? '' : 
              <div className={css['dj-videos-center-content-head']}><img src={videoIcon1} alt="" /><img src={videoIcon2} className={css['dj-videos-center-content-head-icon']} alt=""/>视频<span>中心</span><img src={videoIcon2} className={css['dj-videos-center-content-head-icon1']} alt=""/><img src={videoIcon1} alt=""/></div>
            }
            <div className={this.props.isMobile ? css['dj-videos-center-content-tabH5'] : css['dj-videos-center-content-tab']}>
              {this.state.contentLabelList.map((item, index)=>(<div key={index} className={this.state.contentLabel === item.value ? css['cur'] : ''} onClick={()=>this.getVideo(item.value)}>{item.label}</div>))}
            </div>
          <div className={css['dj-videos-center-content']}>
            <ItemGroup layout={[[32,32,32]]} colGap="30px" model="loop">
              {this.state.list.map((item, index)=>(<Item key={index} item={item} type="video"></Item>))}
            </ItemGroup>
            {
              isLoading ? (<div className={css['loading']}><Spin /></div>) : ''
            }
            {
              list.length === 0 && !isLoading ? <div className={css['no-data']}><img src={noneIcon} alt="" /><p>暂无数据</p></div> :
              list.length < total && !isLoading ? (
                  <div className={css['all-btn']}>
                    <AllBtn onClick={this.getAllList}></AllBtn>
                  </div>
              ) : ''
            }
          </div>
        </div>
    );
  }
}

export default VideosCenter;
