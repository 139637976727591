// import {PureComponent} from 'react';
import css from './index.module.scss'
// import Ellipse from "../../../news/components/Ellipse";

function Item(props) {
  let {item = {}, onClick} = props;
  let {content, title = ''} = item;
  return (
      <div className={css['item']} onClick={onClick}>
        <p className={`${css['item-title']} ellipse`} title={title}>{title}</p>
        <p className={`${css['item-content']} ellipse`} title={content}>{content}</p>
        {/*<p className={css['item-content']}><Ellipse max={110}>{content}</Ellipse></p>*/}
      </div>
  );
}

export default Item;
