/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-17 18:50:55
 * @LastEditors: dingxuejin
 */
import { lazy, PureComponent as Component } from "react";
import { connect } from "react-redux";
import './index.scss'

const Dynamic = lazy(() => import("./components/dynamic"));
const DigCoo = lazy(() => import("./components/digCoo"));
const Boar = lazy(() => import("./components/boar"));
const AppBoar = lazy(() => import("./components/AppBoar"));
const Dec = lazy(() => import("./components/dec"));

@connect(state => ({isMobile: state.getIn(['app', 'isMobile'])}),)
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="dj-home">
              {
                !this.props.isMobile ? (
                  <>
                    <Dec></Dec>
                    <Boar></Boar>
                  </>
                ) : ''
              }
              <DigCoo></DigCoo>
              {
                this.props.isMobile ? (<AppBoar></AppBoar>) : ''
              }
              <Dynamic></Dynamic>
            </div >
        );
    }
}

export default Home;
