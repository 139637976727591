/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-16 22:05:21
 * @LastEditTime: 2021-02-18 17:35:08
 * @LastEditors: dingxuejin
 */

import { PureComponent as Component } from "react";
import PropTypes from 'prop-types';
import css from './index.module.scss';

import { Link } from "react-router-dom";

class DropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: this.props.list || [],
            isShow: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevProps) {
        return {
            isShow: nextProps.index == nextProps.active
        }
    }

    to(e, item) {
        if (item.outLink) window.open(item.path+'?source=siteProductCenter');
        else this.props.history.push(item.path);
        e.preventDefault();
    }


    render() {
        return (
            <div>
                {
                    this.state.isShow ? (< div className={css["lst"]} >
                        { this.state.list.map((itm, i) => (<div className={css["itm"]} key={i} onClick={(e) => { this.to(e, itm) }}>{itm.description}</div>))}
                    </div >) : ""
                }
            </div>

        );
    }
}

DropDown.propTypes = {
    list: PropTypes.array,
    index: PropTypes.number
};

export default DropDown;
