import {PureComponent} from 'react';
import css from './index.module.scss'
import btn_details from '../../../../assets/images/news/btn_details.svg'

class DetailBtn extends PureComponent {
  constructor() {
    super();
  }

  render() {
    return (
        <div className={css['detail-btn']} onClick={this.props.onClick}>
          <span className={css['detail-btn-txt']}>查看详情</span>
          <span className={css['detail-btn-icon']}>
            <img src={btn_details} />
          </span>
        </div>
    );
  }
}

export default DetailBtn;