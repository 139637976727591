/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-14 17:17:23
 * @LastEditTime: 2021-02-19 16:03:30
 * @LastEditors: dingxuejin
 */
import { PureComponent as Component } from "react";
import css from './index.module.scss';

class Con extends Component {
    constructor(props) {
        super(props);
        try {
            let { bgImage, text } = this.props.introData
            this.state = {
                bgImage: bgImage,
                text: text
            };
        } catch (error) {
            this.state = {
                bgImage: "",
                text: ""
            };
        }

    }

    render() {
        let texts = this.state.text || [];
        if (texts && !Array.isArray(texts)) {
            texts = [texts];
        }
        return (
            <div className={css["con"]}>
                <div className={css["lef"]}>
                    {
                        texts.map(t=>(<p>{t}</p>))
                    }
                </div>
                <div className={css["rig"]} style={{
                    backgroundImage: `url(${this.state.bgImage})`
                }} ></div>
            </div >
        );
    }
}

export default Con;
