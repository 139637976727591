import {lazy, PureComponent as Component} from "react";
import {mapRoute as AppRoute} from "@/router";
import './index.scss';
import icon_menu from  './images/icon_menu.png';
import icon_menu_white from  './images/icon_menu_white.png';
import icon_menu_close from  './images/icon_menu_close.png';
import {Link} from "react-router-dom";
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({
        isOpen: false
      })
      this.props.onHide && this.props.onHide()
    })
  }

  componentWillUnmount() {
  }

  onClick = () => {
    let bool = !this.state.isOpen;
    this.setState({isOpen: bool})
    if (bool) {
      this.props.onShow && this.props.onShow()
    } else {
      this.props.onHide && this.props.onHide()
    }
  }

  getListIcon = () => {
    return this.props.isWhiteTheme ? icon_menu : icon_menu_white;
  }

  render() {
    return (
      <div className="header-menu">
        <img className="header-menu-icon" src={this.state.isOpen ? icon_menu_close : this.getListIcon()} alt="" onClick={this.onClick} />
        <div className={`header-menu-list ${this.state.isOpen ? 'isOpen' : ''}`}>
          <Link to="/" className="header-menu-list__item">关于东经</Link>
          <a href="https://yi.djcps.com/?source=siteProductCenter" className="header-menu-list__item">东经易网</a>
          <a href="https://zbc.djcps.com/?source=siteProductCenter" className="header-menu-list__item">经营测评</a>
          {/*<div className="">*/}
            <a href="https://yizhipei.djcps.com/?source=siteProductCenter" className="header-menu-list__item">易智配</a>
            <a href="https://yizhixiang.djcps.com/?source=siteProductCenter" className="header-menu-list__item">易纸箱</a>
            <a href="https://yidanlai.djcps.com/?source=siteProductCenter" className="header-menu-list__item">易单来</a>
          {/*</div>*/}
        </div>
      </div>
    );
  }
}
export default Menu
