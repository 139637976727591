import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import icon_play from '../../../../assets/images/news/icon_play.svg';
import css from './index.module.scss'
import Ellipse from "../Ellipse";
import RateImg from "../RateImg";
import Video from "../../../../components/common/Video";

class Item extends PureComponent {
  constructor() {
    super();
    this.state = {
      isShowVideo: false
    }
  }

  static defaultProps = {
    type: 'img'
  }

  static propTypes = {
    // 类型
    type: PropTypes.oneOf(['img', 'video']),
    // 数据
    item: PropTypes.object,
    // 点击回调函数
    onClick: PropTypes.func,
  }

  onClick = () => {
    let {type = 'img', onClick} = this.props;
    let {isShowVideo} = this.state;
    if (type === 'video' && !isShowVideo) {
      this.setState({
        isShowVideo: true
      });
    }
    onClick && onClick();
  };

  render() {
    let {type = 'img', item = {}} = this.props;
    let {isShowVideo} = this.state;
    let {img, time = '', title = '', videoUrl} = item;
    return (
        <div className={css['item']} onClick={this.onClick}>
          <div className={css['item-img']}>
            <RateImg className={css['img']} img={img} rate={'56.25%'}></RateImg>
            {
              type === 'video' ? (
                  <div className={css['item-img-video-mask']}>
                    <img src={icon_play} alt=""/>
                  </div>
              ) : ''
            }
          </div>
          <div className={css['item-content']}>
            <p className={css['item-time']}>
              <Ellipse max={15}>{time}</Ellipse>
            </p>
            <p className={css['item-title']}>
              <Ellipse max={30}>{title}</Ellipse>
            </p>
          </div>
          {
            isShowVideo && (
                <Video src={videoUrl} onClose={()=>{
                  console.log('setState');
                  this.setState({isShowVideo: false})
                }}></Video>
            ) || ''
          }
        </div>
    );
  }
}

export default Item;