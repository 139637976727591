/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-17 13:58:26
 * @LastEditTime: 2021-02-18 17:54:30
 * @LastEditors: dingxuejin
 */
import AppRoute from "@/router/routes";


class Router {
    constructor() {
        this.routes = [];
        this.loop(AppRoute)
    }
    loop(routes, sub) {
        routes.forEach(route => {
            let { children, redirect, outLink } = route;
            if (sub && !outLink) {
                route.path = sub.path + route.path;
            };
            if (children && children.length > 0) {
                this.loop(route.children, route)
            }

            this.routes.push(route)
        });
    }
    getRoute() {
        return this.routes
    }
}

let router = new Router();

export const routes = router.getRoute();
export const mapRoute = AppRoute;
