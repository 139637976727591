import { Suspense, PureComponent as Component } from "react";
import { Switch, Route, Redirect, BrowserRouter as Router } from "react-router-dom";
import { routes as AppRoute } from "@/router";
import { Provider, connect } from "react-redux";
import store from "./store";
import {changeIsMobile} from "./store/reducer/appReducer";

@connect(null, {changeIsMobile})
class App extends Component {
    handleChild() {

    }

    componentWillMount() {
        this.props.changeIsMobile(document.body.clientWidth < 1201)
        window.addEventListener('resize', () => {
            this.props.changeIsMobile(document.body.clientWidth < 1201)
        })
    }

    render() {
        return (
            <Router>
                <Suspense fallback={""}>
                    <Switch>
                        {AppRoute.map((item, index) => {
                            let { path, layout: Layout, component: View, redirect } = item;
                            if (redirect) {
                                return (
                                  <Redirect
                                    key={index + path}
                                    from={path}
                                    to={path+redirect}
                                  />
                                );
                            }
                            if (View) {
                                function warp(props) {
                                    return <Layout {...props} route={item}><View {...props}></View></Layout>
                                }
                                return (
                                  <Route
                                    key={index + path}
                                    path={path}
                                    component={warp}
                                    exact
                                  />
                                );
                            }
                        })}
                        <Redirect from="/*" to="/"/>
                    </Switch>
                </Suspense>
            </Router>
        );
    }
    // render() {
    //     return (
    //       <Provider store={store}>{this.route()}</Provider>
    //     );
    // }
}

export default App;
