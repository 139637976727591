/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-14 17:39:25
 * @LastEditTime: 2021-02-21 16:56:51
 * @LastEditors: dingxuejin
 */
import { lazy, PureComponent as Component } from "react";
import css from './index.module.scss';
import SlideRight from "../../../components/animation/SlideRight";
import SlideLeft from "../../../components/animation/SlideLeft";
const Con = lazy(() => import("../components/intro/index"));
const Item = lazy(() => import("../components/item/index"));
const Concat = lazy(() => import("@/pages/product/djCardboard/concat"));

class DjCarton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            introData: {
                bgImage: require("@/assets/images/product/djCarton/card_bg_yizhixiang.png").default,
                text: "易纸箱将打通纸包装企业上下游，完成「纸板厂、纸箱厂、东经易网」的闭环，实现为纸箱企业赋能。目前，已有超过700 家纸箱厂通过易纸箱，实现降低成本50%、增加利润97%（厂均）。"
            }
        };
    }

    render() {
        return (
            <div className={css["djCarton"]}>
                <Con introData={this.state.introData}></Con>
                <Item title="纸箱厂痛点">
                    <div className={css["con"]}>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>订单管理混乱</div>
                            <div className={css["des"]}>人工手动记录订单，繁琐、效率低、易出错</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>送货单不规范</div>
                            <div className={css["des"]}>手写送货单费时且不规范、严重影响送货效率</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>算账繁琐</div>
                            <div className={css["des"]}>手动算账，容易漏账错账，直接造成工厂损失</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>不清楚工厂的经营情况</div>
                            <div className={css["des"]}>无法知晓经营情况，忙了一整年却没赚什么钱</div>
                        </div>
                    </div>
                </Item>
                <Item title="全流程覆盖 助力纸箱厂精细化管理">
                    <div className={css["con"]}>
                        <div className={css["itm"]}>
                                <div className={css["lef"]} >
                                    <SlideRight display="inline-block">
                                        <div className={css["titl"]}>建立纸箱厂数字化管理</div>
                                        <div className={css["des"]}>
                                            <div>纸箱通过四大核心功能（订单管理、送货单管理、对账单管理、微信小程序），将订单、生产、财务等功能相互关联，实现重复订单 10 秒完成、送货单一键打印，并有效远离漏帐和错账，降低人工成本、提高工作效率、减少错误损失。</div>
                                        </div>
                                    </SlideRight>
                                </div>
                            <SlideLeft display="inline-block">
                                <div className={css["rig"]}></div>
                            </SlideLeft>
                        </div>
                        <div className={css["itm"]}>
                            <SlideRight display="inline-block">
                                <div className={css["rig"]}></div>
                            </SlideRight>
                            <div className={css["lef"]} >
                                <SlideLeft display="inline-block">
                                    <div className={css["titl"]}>多维度服务</div>
                                    <div className={css["des"]}>从解决纸箱爆裂、印版管理等日常难题，到企业礼仪和生产安全的知识讲解，易纸箱为纸箱厂提供更垂直的行业解决方案，多维度服务帮助企业优质管理、打造口碑！</div>
                                </SlideLeft>
                            </div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["lef"]} >
                                <SlideRight display="inline-block">
                                    <div className={css["titl"]}>行业专家鼎力相助</div>
                                    <div className={css["des"]}>易纸箱百人研发服务团队，一对一为用户提供易纸箱系统的学习培训和售后服务。齐心协力，共同开发易纸箱解决方案，为全国数十万家纸箱厂保驾护航。</div>
                                </SlideRight>
                            </div>
                            <SlideLeft display="inline-block">
                                <div className={css["rig"]}></div>
                            </SlideLeft>
                        </div>
                    </div>
                </Item>
                <Item title="产品优势" color="#fff">
                    <div className={css["lst"]}>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["inf"]}>
                                <div className={css["num"]}>广调研</div>
                                <div className={css["des"]}>
                                    走访7000家纸箱厂，为解决纸箱厂生产、经营全流程痛点应运而生。
                                </div>
                            </div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["inf"]}>
                                <div className={css["num"]}>深垂直</div>
                                <div className={css["des"]}>
                                    20余年行业经验，梳理纸箱厂全流程痛点，沉淀多维度解决方案。
                                </div>
                            </div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["inf"]}>
                                <div className={css["num"]}>重结果</div>
                                <div className={css["des"]}>
                                    700+家纸箱厂通过易纸箱，降低成本50%，增加利润97%(厂均)。
                                </div>
                            </div>
                        </div>
                    </div>
                </Item>
                <Item>
                    <Concat {...this.props}>联系我们，解决您纸箱厂的经营难题</Concat>
                </Item>
            </div >
        );
    }
}

export default DjCarton;