import { fromJS } from 'immutable'; //用这个包创建的对象不可被更改
const DEFAULT_STATE = fromJS({
  // 是否屏蔽头部的滚动监听
  shieldHeaderScrollListen: false
});

const ACTION_NAME = {
  changeHeaderScrollShield: 'changeHeaderScrollShield',
}

const actionMap = {
  [ACTION_NAME.changeHeaderScrollShield]: (state, action)=>{
    return state.set('shieldHeaderScrollListen', action.preload);
  }
};

export default (state = DEFAULT_STATE, action) => {
  let fn = actionMap[action.type];
  if (fn) {
    return fn(state, action);
  }
  return state
}

export const changeHeaderScrollShield = (val) => {
  return {
    type: ACTION_NAME.changeHeaderScrollShield,
    preload: val
  }
}
